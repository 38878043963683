const POPUP_ERROR_MESSAGE_FF_LOCAL = 'newWindow is null';
const POPUP_ERROR_MESSAGE_FF = 'f is null';
const HUBSPOT_ANDROID_UA = /^HubSpotAndroid\//;
const HUBSPOT_IOS_UA = /^HubSpotiOS\//;
const POPUP_ERRORS_TO_IGNORE = new Set([POPUP_ERROR_MESSAGE_FF_LOCAL, POPUP_ERROR_MESSAGE_FF]);
const lowercasedUserAgent = window.navigator ? navigator.userAgent.toLowerCase() : '';
const isEdge = () => {
  return lowercasedUserAgent.includes('edge/');
};
const isSafari = () => lowercasedUserAgent.includes('safari/') && !lowercasedUserAgent.includes('chrome/') && !isEdge();
const isIE = () => {
  return /Trident\/|MSIE/.test(window.navigator.userAgent);
};
export const doesSupportPopup = () => {
  return !isSafari() && !isIE();
};
export const isPopupError = message => {
  return POPUP_ERRORS_TO_IGNORE.has(message);
};
export const isHubSpotMobile = () => {
  const userAgent = typeof window.navigator !== 'undefined' ? window.navigator.userAgent || '' : '';
  return HUBSPOT_ANDROID_UA.test(userAgent) || HUBSPOT_IOS_UA.test(userAgent);
};